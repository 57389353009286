<template>
    <div>
        <ts-page-title
            :title="$t('role.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                { text: $t('role.pageTitle'), href: '/admin/roles' },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="demo-spin-article">
                    <div class="tw-flex tw-items-center tw-justify-end">
                        <label class="required tw-pr-4">{{
                            $t("role.roleName")
                        }}</label>
                        <div style="width:300px">
                            <input
                                v-model.trim="model.name"
                                class="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-3 tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                                type="text"
                                :class="{ 'is-invalid': errors.has('name') }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('name')"
                            >
                                {{ errors.first("name") }}
                            </div>
                        </div>
                    </div>
                    <br />
                    <form class="form-horizontal">
                        <div class="row ts-mt-4">
                            <div class="col-md-12">
                                <div id="container">
                                    <table
                                        class="table table-hover ui-table tw-table"
                                    >
                                        <thead>
                                            <tr>
                                                <th
                                                    class="ts-sticky ts-top-0 ts-z-10"
                                                >
                                                    <input
                                                        v-model="
                                                            searchPermissionText
                                                        "
                                                        type="text"
                                                        style="width:300px"
                                                        class="tw-shadow tw-appearance-none tw-border tw-rounded tw-w-full tw-py-3 tw-px-3 tw-text-gray-700 tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline"
                                                        :placeholder="
                                                            $t('search')
                                                        "
                                                    />
                                                </th>
                                                <th
                                                    v-for="column in permissionColumns"
                                                    :key="column"
                                                    class="tw-sticky tw-top-0 tw-z-3 tw-w-20 tw-text-center"
                                                >
                                                    <div
                                                        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full"
                                                    >
                                                        <p class="tw-mb-0">
                                                            {{
                                                                $t(
                                                                    column.toLowerCase()
                                                                )
                                                            }}
                                                        </p>
                                                        <ts-checkbox
                                                            :checkedValue="
                                                                allCheckedBy(
                                                                    column
                                                                )
                                                            "
                                                            @change="
                                                                checked =>
                                                                    selectAllBy(
                                                                        column,
                                                                        checked
                                                                    )
                                                            "
                                                        />
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template
                                                v-for="(group,
                                                groupName) in permissionGroups"
                                            >
                                                <tr :key="group.id">
                                                    <td>
                                                        <span
                                                            class="tw-text-sm tw-font-semibold tw-pt-6 tw-inline-block"
                                                            >{{
                                                                groupName
                                                            }}</span
                                                        >
                                                    </td>
                                                    <td
                                                        v-for="n in permissionColumns.length"
                                                        :key="n"
                                                    >
                                                        <div
                                                            class="tw-flex tw-justify-center tw-pt-6"
                                                        >
                                                            <ts-checkbox
                                                                :checkedValue="
                                                                    allCheckedByGroup(
                                                                        groupName,
                                                                        permissionColumns[
                                                                            n -
                                                                                1
                                                                        ]
                                                                    )
                                                                "
                                                                @change="
                                                                    checked =>
                                                                        selectByGroup(
                                                                            groupName,
                                                                            permissionColumns[
                                                                                n -
                                                                                    1
                                                                            ],
                                                                            checked
                                                                        )
                                                                "
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-for="(permissions,
                                                    resourceName) in group"
                                                    :key="permissions.id"
                                                >
                                                    <td>
                                                        <span
                                                            class="tw-ml-4 tw-mr-6"
                                                        >
                                                            {{ resourceName }}
                                                        </span>
                                                    </td>
                                                    <td
                                                        v-for="n in permissionColumns.length"
                                                        :key="n"
                                                    >
                                                        <div
                                                            class="tw-flex tw-justify-center"
                                                        >
                                                            <ts-checkbox
                                                                v-model="
                                                                    model.permissions
                                                                "
                                                                :value="
                                                                    isShow(
                                                                        permissions,
                                                                        n
                                                                    ).id
                                                                "
                                                                v-if="
                                                                    isShow(
                                                                        permissions,
                                                                        n
                                                                    ) !==
                                                                        undefined
                                                                "
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                                <div
                                    class="validate"
                                    v-if="errors.has('permissions')"
                                >
                                    {{ errors.first("permissions") }}
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div
                                class="col-md-12 tw-flex tw-justify-end tw-space-x-3"
                            >
                                <ts-button
                                    class="mr-2"
                                    @click.prevent="
                                        $router.push({ name: 'role' })
                                    "
                                    >{{ $t("cancel") }}</ts-button
                                >
                                <ts-button
                                    outline
                                    color="primary"
                                    class="mr-2"
                                    :waiting="waiting_new"
                                    @click.prevent="saveAddNew"
                                    :disabled="waiting"
                                >
                                    {{ $t("saveAddNew") }}</ts-button
                                >
                                <ts-button
                                    color="primary"
                                    :waiting="waiting"
                                    @click.prevent="save"
                                    :disabled="waiting_new"
                                >
                                    {{ $t("save") }}</ts-button
                                >
                            </div>
                        </div>
                    </form>
                </div>
                <Spin size="large" fix v-if="loading"> </Spin>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { Errors } from "form-backend-validation";
import {
    union,
    uniq,
    difference,
    intersection,
    flatMap,
    map,
    filter
} from "lodash";

export default {
    name: "roleCreate",
    data() {
        return {
            model: {
                name: "",
                permissions: []
            },
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false
        };
    },
    created() {
        this.fetchPermissions();
    },
    computed: {
        ...mapState("auth/role", ["permissions"]),
        ...mapGetters("auth/role", ["permissionGroups", "permissionColumns"]),
        searchPermissionText: {
            get() {
                return this.$store.state.auth.role.searchPermissionText;
            },
            set(newValue) {
                this.$store.commit("auth/role/SET_SEARCH_PERMISSION", newValue);
            }
        },
        idPermissionsBy: vm => column => {
            return uniq(
                map(
                    filter(vm.permissions, p => {
                        return p.name
                            .toLowerCase()
                            .startsWith(column.toLowerCase());
                    }),
                    "id"
                )
            );
        },
        allCheckedBy: vm => column => {
            const idPermissions = vm.idPermissionsBy(column);

            return (
                intersection(vm.model.permissions, idPermissions).length ===
                idPermissions.length
            );
        },
        idPermissionsGroup: vm => (groupName, column) => {
            return uniq(
                map(
                    filter(flatMap(vm.permissionGroups[groupName]), p => {
                        return p.name
                            .toLowerCase()
                            .startsWith(column.toLowerCase());
                    }),
                    "id"
                )
            );
        },
        allCheckedByGroup: vm => (groupName, column) => {
            const idPermissions = vm.idPermissionsGroup(groupName, column);

            return (
                intersection(vm.model.permissions, idPermissions).length ===
                idPermissions.length
            );
        },
        isShow: () => (permission, pOrder) => {
            switch (pOrder) {
                case 1:
                    return permission.filter(
                        p => p.name.toLowerCase().indexOf("view") >= 0
                    )[0];
                case 2:
                    return permission.filter(
                        p => p.name.toLowerCase().indexOf("create") >= 0
                    )[0];

                case 3:
                    return permission.filter(
                        p => p.name.toLowerCase().indexOf("update") >= 0
                    )[0];

                case 4:
                    return permission.filter(
                        p => p.name.toLowerCase().indexOf("delete") >= 0
                    )[0];

                default:
                    return undefined;
            }
        }
    },
    methods: {
        ...mapActions("auth/role", ["store"]),
        fetchPermissions(attributes) {
            this.loading = true;

            this.$store
                .dispatch("auth/role/fetchPermissions", attributes)
                .finally(() => (this.loading = false));
        },
        selectAllBy(column, checked) {
            if (checked) {
                this.model.permissions = union(
                    this.model.permissions,
                    this.idPermissionsBy(column)
                );
            } else {
                this.model.permissions = difference(
                    this.model.permissions,
                    this.idPermissionsBy(column)
                );
            }
        },
        selectByGroup(groupName, column, checked) {
            if (checked) {
                this.model.permissions = union(
                    this.model.permissions,
                    this.idPermissionsGroup(groupName, column)
                );
            } else {
                this.model.permissions = difference(
                    this.model.permissions,
                    this.idPermissionsGroup(groupName, column)
                );
            }
        },
        filterPermission() {
            this.model.name = "";
            this.model.permissions = [];
            this.fetchPermissions({
                guard_name: this.model.guard_name
            });
        },
        save() {
            this.errors = new Errors();
            this.waiting = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                    this.$router.push({ name: "role" });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        saveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.store(this.model)
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.clearInput();
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        clearInput() {
            (this.model.name = ""), (this.model.permissions = []);
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "ROLE",
                desc: not.text
            });
        }
    }
};
</script>

<style lang="css" scoped>
#container {
    min-height: 40vh;
    max-height: 60vh;
    overflow-y: scroll;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    border-color: #e2e7eb;
    padding: 4px 2px !important;
}
.table > :not(:first-child) {
    border-top: 1px solid grey;
}
</style>
